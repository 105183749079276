window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#63D7BA"
        },
        "button": {
            "background": "#2e4960"
        }
    }
});

$('#shareButton').on('click', function() {
    var facebook = $('.share-icon--facebook'),
        twitter = $('.share-icon--twitter'),
        pinterest = $('.share-icon--pinterest'),
        email = $('.share-icon--email')

    $(this).toggleClass('btn--disabled');
    $('.share-icon').toggleClass('share-icon--active');

    if ($('.share-icon').hasClass('share-icon--active')) {

        facebook.show();
        twitter.show();
        pinterest.show();
        email.show();

        var shareTimeline = new TimelineLite();
        shareTimeline.add( TweenLite.to( facebook, .5, {ease: Linear.easeOut,x: -250, y: 0, opacity: 1}), 0 );
        shareTimeline.add( TweenLite.to( twitter, .5, {ease: Linear.easeOut,x: -200, y: 0, opacity: 1}), 0.1 );
        shareTimeline.add( TweenLite.to( pinterest, .5, {ease: Linear.easeOut,x: -150, y: 0, opacity: 1}), 0.2 );
        shareTimeline.add( TweenLite.to( email, .5, {ease: Linear.easeOut,x: -100, y: 0, opacity: 1}), 0.3 );

    } else {

        facebook.show();
        twitter.show();
        pinterest.show();
        email.show();

        var shareTimeline = new TimelineLite();
        shareTimeline.add( TweenLite.to( facebook , .5, {ease: Linear.easeOut,x: 0, y: 0, opacity: 0}) , 0.3 );
        shareTimeline.add( TweenLite.to( twitter , .5, {ease: Linear.easeOut,x: 0, y: 0, opacity: 0}) , 0.2 );
        shareTimeline.add( TweenLite.to( pinterest , .5, {ease: Linear.easeOut,x: 0, y: 0, opacity: 0}) , 0.1 );
        shareTimeline.add( TweenLite.to( email , .5, {ease: Linear.easeOut,x: 0, y: 0, opacity: 0}) , 0 );

    }
});

$("#priceSlider").slider();

$("#priceSlider").on("slide", function (e) {
    var minPrice = e.value[0],
        maxPrice = e.value[1];

    $("#sliderFrom").text("£" + minPrice), $("#sliderTo").text("£" + maxPrice);
});

$("#propertiesCarousel").slick({
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    arrows: true,
    nextArrow: $('.front-page__arrow-bg--next'),
    prevArrow: $('.front-page__arrow-bg--prev'),
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            arrows: false
        }
    },
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            arrows: false
        }
    }, 
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false
        }
    }]
}), 

$("#quotesCarousel").slick({
    infinite: true,
    arrows: true,
    nextArrow: '<div class="slick-arrow__next"><span class="fa fa-angle-right fa-2x"></span></div>',
    prevArrow: '<div class="slick-arrow__prev"><span class="fa fa-angle-left fa-2x"></span></div>',
    speed: 500,
    dots: false,
    responsive: [{
        breakpoint: 991,
        settings: {
            dots: true,
            arrows: false
        }
    }]
}),

$("#propertyCarousel").slick({
    nextArrow: '<div class="slick-arrow__next"><span class="fa fa-angle-right fa-2x"></span></div>',
    prevArrow: '<div class="slick-arrow__prev"><span class="fa fa-angle-left fa-2x"></span></div>',
    arrows: true
});

$('#teamCarousel').slick({
    arrows: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true
        }
    },
    {
        breakpoint: 575,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        }
    }]
});

var $header = $("header");

$header.find("#mobileNavButton").on("click", function () {
    "menu" == $(this).text().toLowerCase() ? ($("html, body").addClass("no-overflow"), $(this).text("close")) : ($("html, body").removeClass("no-overflow"), $(this).text("menu")), 
    $header.find('.nav__tel--big').toggle();
    $header.find(".nav__logo").toggleClass("nav__logo--hide"), 
    $header.find(".nav__items").toggleClass("nav__items--open"), 
    $header.find(".nav__contact-info, .nav__background, .nav__logos").toggle(), 
    $(this).toggleClass("btn--white");
}), 

$gallery = $(".single-property__image-gallery"), 

$gallery.find(".single-property__image--small").on("click", function (e) {
    if (!($(".single-property__image--small").length < 2)) {
        $gallery.find("#propertyCarousel").slick("slickGoTo", $(this).data("key"));
    }
}), 

$gallery.find(".single-property__background").on("click", function () {
    $(this).hasClass("active") && ($(this).removeClass("active"), $(".single-property__carousel").removeClass("active"));
}), 

$(window).scroll(function () {
    100 < $(this).scrollTop() ? $("#returnToTop").addClass("active") : $("#returnToTop").removeClass("active");
}), 

$("#returnToTop").on("click", function () {
    $("html, body").animate({
        scrollTop: 0
    });
});

lightbox.option({
    'alwaysShowNavOnTouchDevices': true
});